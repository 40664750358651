// debug - debugging statements to the console

function table () {
  window.debug && console.table(...arguments);
}

function log () {
  window.debug && console.log(...arguments);
}

// show coords in log
function logCoords (coords) {
  const loc = window.location;
  const url = `${loc.protocol}//${loc.host}/logcoords.html` +
    `?lat=${coords.latitude},lon=${coords.longitude},altitude=${coords.altitude}`;
  // log(`logCoords url: '${url}'`);
  fetch(url).then(response => {
    // log(`logCoords response:`, response);
  });
}

/**
 * create a CHistory 'statistics' string
 * @param {CHistory} cHistory
 * @return {string}
 */
function cHistoryStats (cHistory) {
  const {records, distanceRange, timeRange, isSufficient} = cHistory;
  return window.debug ?
    `<span class="text-secondary small">r:${records}
      d:${(distanceRange).toFixed(0)}
      t:${(timeRange / 1000).toFixed(0)}
      s:${isSufficient ? 't' : 'f'}</span>`
    : "";
}

export default { log, table, logCoords, cHistoryStats };
