// grade-section.hs - section of display that shows 'grade'

import {
  isCoordsHistoryEmpty,
  getSufficientHistory,
  // hasSufficientHistory
} from './coords-history.js';
import { updateSection } from './update-section.js';
import {
  constants,
  historyCriteria,
  WimeConf,
  isCoordsAccuracySufficient
} from './wime-conf.js';
import { linearRegression } from './linear-regression.js';
import debug from './debug.js';

/**
 * update the Grade section
 * @param {WimeCoords} coords - coords of the most recent history
 */
export function updateGradeSection (coords) {

  const disp = {
    title: 'Grade',
    value: null,
    units: '<div class="percent">%</div>',
    direction: 0,
    footnote: 'recording'
  };

  const cHistory =
    getSufficientHistory(coords, historyCriteria('grade'));

  const {
    distances, altitudes, altRange, avgAltAccuracy, distanceRange,
    isSufficient
  } = cHistory;

  const stats = debug.cHistoryStats(cHistory);

  // accuracy must be sufficient
  if (!isCoordsAccuracySufficient(coords)) {
    disp.footnote =
      `${stats} <span class="text-warning">poor accuracy</span>`;
    return updateSection(WimeConf.divGrade, disp);
  }

  // history must be sufficient
  if (!isSufficient) {
    disp.footnote =
      `${stats} <span class="text-info">measuring…</span>`;
    return updateSection(WimeConf.divGrade, disp);
  }

  // altitude range must be > min altitude-to-accuracy ratio
  const altRangeToAccuracyRatio = altRange / avgAltAccuracy;
  if (altRangeToAccuracyRatio < constants.MIN_ALTITUDE_TO_ACCURACY_RATIO) {
    const altRangeFt = altRange / constants.METERS_PER_FOOT;
    disp.value = 0;
    disp.footnote = `${stats}
      <span class="text-info">low change
      &plusmn; ${(altRangeFt/2).toFixed(1)}
      ft</span>`;
    return updateSection(WimeConf.divGrade, disp);
  }

  // good data, show it
  const {slope} = linearRegression(distances, altitudes);
  disp.value = Math.round(slope * 100);
  disp.direction = disp.value;
  disp.footnote =
    `${stats} <span class="text-success">over prior ${
      Math.round(distanceRange / constants.METERS_PER_FOOT)
    } ft`;
  updateSection(WimeConf.divGrade, disp);
}
