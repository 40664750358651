// ugly-focus-ring.js - hide ugly focus ring that shows after user clicks
// inspiration: https://medium.com/hackernoon/removing-that-ugly-focus-ring-and-keeping-it-too-6c8727fefcd2

// note this code exports nothing, it just runs

import debug from './debug.js';

const STYLESHEET_ID = 'tms-focus-ring-styles';

const cssRules = `
  body:not(.user-is-tabbing) button:focus,
  body:not(.user-is-tabbing) input:focus,
  body:not(.user-is-tabbing) select:focus,
  body:not(.user-is-tabbing) textarea:focus {
    outline: none;
  }
`;

// install style sheet into <head>
function addStyleSheetToHead (rules) {
  const styleSheet = document.createElement('style');
  styleSheet.id = STYLESHEET_ID;
  styleSheet.textContent = cssRules;
  document.head.appendChild(styleSheet);
}

function handleFirstTab(e) {
  if (e.key === 'Tab') {
    // debug.log('handleFirstTab: I saw a tab key!');
    document.body.classList.add('user-is-tabbing');
    window.removeEventListener('keydown', handleFirstTab);
  }
}

window.addEventListener('load', addStyleSheetToHead);
window.addEventListener('keydown', handleFirstTab);
