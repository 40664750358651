// get-coords.js - get coordinates including elevation

import { WimeCoords } from './wime-coords.js';
import { elevationSourceName, wcGeo } from './wime-conf.js';
import { getOpenElevation } from './open-elevation.js';
import { getCurrentPosition } from './geolocation.js';
import { getZeroTime, latestCoords } from './coords-history.js';
import debug from './debug.js';
import { haversineDistance } from './haversine.js';

/**
 * calculate distanceOffset from last stored position
 * @param {Number} latitude
 * @param {Number} longitude
 * @param {WimeCoords} [priorCoords]
 * @return {number}
 */
export function getDistanceOffset (latitude,
                            longitude,
                            priorCoords = undefined) {
  const lastCoords = priorCoords ||
    latestCoords() ||
    {latitude, longitude, distanceOffset: 0};
  const distanceOffset = haversineDistance(
    {latitude, longitude}, lastCoords) + lastCoords.distanceOffset;
  return distanceOffset;
}

/**
 * get the current coordinates with elevation and the named source of the data
 * @param {GeolocationPosition} [position]
 * @return {Promise<WimeCoords>}
 */
export default function getWimeCoordsFromGeoPosition (
  position = undefined
) {

  // debug.log('getWimeCoordsFromGeoPosition() position:', position);
  let timestamp;

  return new Promise(resolve => resolve())

    .then(() => {
      return getCurrentPosition(position)
        .then(gcpos => {
          timestamp = gcpos.timestamp;
          return gcpos.coords;
        });
    })

    .then(coords => {
      // get elevation from open-elevation if necessary
      if (coords.altitude === null) {
        return getOpenElevation(coords);
      } else {
        const wc = new WimeCoords(coords);
        wc.source = elevationSourceName.gps;
        return wc;
      }
    })

    .then(wc => {
      // return to caller with WimeCoords
      wc.distanceOffset = getDistanceOffset(wc.latitude, wc.longitude);
      wc.timeOffset = timestamp - getZeroTime(timestamp);
      // debug.log('getWimeCoordsFromGeoPosition returning wc:', wc);
      return wc;
    })
    .catch(err => {
      console.error('getWimeCoordsFromGeoPosition error:', err);
      throw err;
    });
}
