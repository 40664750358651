// coords-history.js - record history of coordinates

import {
  constants,
  hasCoordsMovedBeyondAccuracy,
  isCoordsAccuracySufficient
} from './wime-conf.js';
import './wime-coords.js';
import debug from './debug.js';
import { WimeCoords } from './wime-coords.js';

class CHistory extends Object {
  constructor () {
    super();
    this.records = 0;
    this.distances = [];
    this.distanceRange = 0;
    this.times = [];
    this.timeRange = 0;
    this.altitudes = [];
    this.altRange = 0;
    this.avgAltAccuracy = 0;
    this.isSufficient = false;
  }
}

let coordsHistory = [];
let zeroTime = 0;  // epochtime of first history coords

/**
 * set the 'zero' time to the specified epoch time or the current time
 * 'zero' time: epoch time from which to start counting
 * @param {number} [epochTime]
 */
function setZeroTime (epochTime = Date.now()) {
  zeroTime = epochTime;
}

/**
 * get 'zero' time
 * if 'zero' time === 0, sets 'zero' time to epochTime
 * @param {number} epochTime
 * @return {number}
 */
export function getZeroTime (epochTime) {
  if (zeroTime === 0) {
    setZeroTime(epochTime);
  }
  return zeroTime;
}

/**
 * remove coords from history that are too old to keep
 * @param timeOffset
 * @return {void}
 */
export function removeStaleHistory (timeOffset) {
  // debug.log(`removeStaleHistory(timeOffset=${timeOffset})`);
  const keepTime = timeOffset - constants.HISTORY_TIME_MAX;
  coordsHistory = coordsHistory.filter(
    ({timeOffset, altitude}) => timeOffset >= keepTime);
}

/**
 * add coords to coordinate history
 * @param {WimeCoords} coords
 * @return {Boolean} true if noew entry was added to history
 */
export function recordNewCoords (coords) {

  const {
    latitude, longitude, accuracy, distanceOffset, altitude,
    altitudeAccuracy, source, timeOffset
  } = coords;

  removeStaleHistory(timeOffset);

  // don't record coords with poor accuracy
  if (isCoordsAccuracySufficient(coords) === false) {
    // debug.log(`recordNewCoords: coords not accurate enough to store`);
    return false;
  }

  // with tiny position changes, merge coords into latest history entry
  const wcLatest = latestCoords();
  if (!hasCoordsMovedBeyondAccuracy(wcLatest, coords)) {
    debug.log(`recordNewCoords small change ignoring`);
    return false;
  }

  // create a new history entry
  let chEntry = new WimeCoords();
  chEntry.cloneFromObject({
    latitude,
    longitude,
    accuracy: (accuracy) ?
      Number(accuracy.toFixed(2)) : null,
    distanceOffset: Number(distanceOffset.toFixed(6)),
    altitude: Number(altitude.toFixed(6)),
    altitudeAccuracy: Number(altitudeAccuracy.toFixed(6)),
    source,
    timeOffset
  });

  coordsHistory.push(chEntry);

  debug.table(coordsHistory.length ? coordsHistory :
    [{latitude: 'no history', longitude: 'no history'}]);

  return true;
}

/**
 * return from history the latest WimeCoords
 * @return {null|WimeCoords}
 */
export function latestCoords () {
  if (coordsHistory.length === 0) { return null; }
  const chLast = coordsHistory[coordsHistory.length - 1];
  const wc = new WimeCoords();
  wc.cloneFromWimeCoords(chLast);
  return wc;
}

export function isCoordsHistoryEmpty () {
  return coordsHistory.length === 0;
}

export function hasCoordsHistory () {
  return coordsHistory.length > 0;
}

/**
 * get a range of coords history that meets the criteria for minDistance and
 * minTime
 * @param {WimeCoords} coords
 * @param {HistoryCriteria} crit
 * @return {CHistory}
 */
export function getSufficientHistory (coords,
                                      crit) {

  // debug.log(`getSufficientHistory() coords:`, coords);

  const {minDistance, minTime, minHistory} = crit;
  let cHistory = new CHistory();

  // find the range of history records that just meets the requirements
  let foundDistance = false, foundTime = false, foundHistory = false,
    sumAltAccuracies = 0, altMin = Infinity, altMax = -Infinity;

  // accumulate right to left (newest to oldest)
  coordsHistory.reduceRight((acc, curr) => {

    // done when all criteria are met
    if (foundDistance && foundTime && foundHistory) {
      return acc;
    }

    // add history
    acc.records++;
    acc.distances.unshift(curr.distanceOffset);
    acc.times.unshift(curr.timeOffset);
    acc.altitudes.unshift(curr.altitude);
    altMin = Math.min(altMin, curr.altitude);
    altMax = Math.max(altMax, curr.altitude);
    sumAltAccuracies += curr.altitudeAccuracy;

    acc.distanceRange = coords.distanceOffset - curr.distanceOffset;
    acc.timeRange = coords.timeOffset - curr.timeOffset;

    if (acc.records >= minHistory) {
      foundHistory = true;
    }
    if (acc.distanceRange >= minDistance) {
      foundDistance = true;
    }
    if (acc.timeRange >= minTime) {
      foundTime = true;
    }
    return acc;
  }, cHistory);

  cHistory.avgAltAccuracy = (cHistory.records > 0)
    ? sumAltAccuracies / cHistory.records
    : Infinity;
  cHistory.altRange = altMax - altMin;

  // mark sufficient when all criteria are met
  cHistory.isSufficient = foundHistory && foundDistance && foundTime;

  // debug.log('  getSufficientHistory cHistory:', cHistory);

  return cHistory;
}
