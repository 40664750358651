// vam-section.js - my elevation vam display

import './wime-coords.js';
import {
  isCoordsHistoryEmpty,
  getSufficientHistory,
  // hasSufficientHistory
} from './coords-history.js';
import { updateSection } from './update-section.js';
import {
  constants,
  historyCriteria,
  isCoordsAccuracySufficient,
  WimeConf
} from './wime-conf.js';
import { linearRegression } from './linear-regression.js';
import debug from './debug.js';

/**
 * update the VAM (ascent rate) section of display
 * @param {WimeCoords} coords - coords of the most recent history
 */
export function updateVAMSection (coords) {

  const disp = {
    title: 'Ascent Rate',
    value: null,
    units: 'f<br>h',
    direction: 0,
    footnote: 'recording'
  };
  
  const cHistory = getSufficientHistory(
    coords, historyCriteria('vam')
  );
  const {
    records, times, altitudes, altRange, avgAltAccuracy, distanceRange,
    timeRange, isSufficient
  } = cHistory;

  const stats = debug.cHistoryStats(cHistory);

  // accuracy must be sufficient
  if (!isCoordsAccuracySufficient(coords)) {
    disp.footnote =
      `${stats} <span class="text-warning">poor accuracy</span>`;
    return updateSection(WimeConf.divVAM, disp);
  }

  // history must be sufficient
  if (!isSufficient) {
    disp.footnote =
      `${stats} <span class="text-info">measuring…</span>`;
    return updateSection(WimeConf.divVAM, disp);
  }

  // history must have wide enough altitude range
  if (altRange / avgAltAccuracy < constants.MIN_ALTITUDE_TO_ACCURACY_RATIO) {
    debug.log(`update VAM altRange: ${altRange} too small, no ascent`);
    const altRangeFt = altRange / constants.METERS_PER_FOOT;
    disp.value = 0;
    disp.footnote = `${stats}
      <span class="text-info">low change
      &plusmn; ${(altRangeFt/2).toFixed(1)}
      ft</span>`;
    return updateSection(WimeConf.divVAM, disp);
  }

  const {slope} = linearRegression(times, altitudes);

  // slope of altitude line must not be infinite
  if (Math.abs(slope) === Infinity) {
    // debug.log('update VAM slope of altitude line is Infinity');
    disp.footnote = `${stats} ` +
      `<span class="text-danger">INFINITE SLOPE!</span>`;
    return updateSection(WimeConf.divVAM, disp);
  }

  const vamMetersPerHr = slope * (1000 * 60 * 60);

  // don't display tiny VAMS
  if (Math.abs(vamMetersPerHr) < constants.MIN_VAM_TO_DISPLAY) {
    // debug.log(`vamMetersPerHour: ${vamMetersPerHr} too small to display`);
    const vamFtPerHr = vamMetersPerHr / constants.METERS_PER_FOOT;
    disp.value = 0;
    disp.footnote = `${stats} ` +
      `<span class="text-info">small VAM ${vamFtPerHr.toFixed()} f/h</span>`;
    return updateSection(WimeConf.divVAM, disp);
  }

  // display vam in feet per hour, rounded to 25-foot increments
  const vamDisplay = vamMetersPerHr / constants.METERS_PER_FOOT;
  disp.value = Math.round(vamDisplay / 25) * 25;
  disp.direction = disp.value;
  disp.footnote =
    `${stats} 
    <span class="text-success">over prior
    ${(timeRange / 1000).toFixed(0)}
    seconds</span>`;

  updateSection(WimeConf.divVAM, disp);
}
