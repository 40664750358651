// elevation-section.js - the elevation section of the display

import { constants, WimeConf } from './wime-conf.js';
import { updateSection } from './update-section.js';
import debug from './debug.js';

/**
 * update the Elevation section of display
 * @param {WimeCoords} wCoords
 * @return {void}
 */
export function updateElevationSection (wCoords) {

  const {
    altitude,
    altitudeAccuracy,
    source
  } = wCoords;

  const disp = {
    title: 'Elevation',
    value: Math.round(altitude / constants.METERS_PER_FOOT),
    units: 'f<br>t',
    direction: 0,
    footnote: ''
  };

  if (source === 'GPS') {
    const accuracyFt = (altitudeAccuracy / constants.METERS_PER_FOOT)
      .toFixed(0);
    disp.footnote = `&plusmn; ${accuracyFt} ft`;
    if (altitudeAccuracy > constants.REQUIRED_ALTITUDE_ACCURACY) {
      disp.footnote += ` <span class="text-warning">poor accuracy</span>`;
    }
  } else {
    disp.footnote = `(${source})`;
  }
  updateSection(WimeConf.divElevation, disp);
}
