// geolocation.js - the navigator.geolocation service

import debug from './debug.js';

const wcGeo = navigator.geolocation;

/**
 *
 * @param {GeolocationPosition} [position]
 * @return {Promise<GeolocationPosition>}
 */
export function getCurrentPosition (position = undefined) {
  return new Promise((resolve, reject) => {
    if (position) {
      return resolve(position);
    }

    wcGeo.getCurrentPosition(pos => {
      resolve(pos);
    }, err => {
      reject(err);
    });
  })
    /** @type {GeolocationPosition} pos **/
    .then(pos => {
      // debug.log('getCurrentPosition returning pos.coords:', pos.coords);
      return pos;
    })
    .catch(err => {
      console.error(`getCurrentPosition err:`, err);
      throw err;
    });
}
