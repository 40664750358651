// open-elevation.js - obtain elevation data from open-elevation.com

import { WimeCoords } from './wime-coords.js';
import { elevationSourceName } from './wime-conf.js';
import debug from './debug.js';

/**
 * get coordinates from open-elevation.com
 * @param {GeolocationCoordinates} coords
 * @return {Promise<WimeCoords>}
 */
export function getOpenElevation (coords) {
  const getOpenEl = 'https://api.open-elevation.com/api/v1/lookup';

  const {latitude, longitude, accuracy} = coords;

  return fetch(getOpenEl + '?' + new URLSearchParams({
    locations: `${latitude},${longitude}`
  }), {method: 'GET'})
    .then(response => response.json())
    .then(json => {
      const result = json.results[0];
      // debug.log('  getOpenElevation result:', result);
      const wimeCoords = new WimeCoords(coords);
      wimeCoords.altitude = Number(result.elevation);
      wimeCoords.altitudeAccuracy = Infinity;
      wimeCoords.source = elevationSourceName.openElevation;
      return wimeCoords;
    })
    .catch(err => {
      console.error('fetch err:', err);
      throw err;
    });
}
