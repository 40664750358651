// activity-indicator.js - blinks an indicator in upper right hand corner of
// screen

let divActivity = null;

const ACTIVITY_CLASSNAME = 'activity-indicator';
const STYLESHEET_ID = 'activity-indicator-styles';

const cssRules = `
    .activity-indicator {
      position: fixed;
      top: 0;
      right: 2px;
      width: 0;
      height: 0;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-right: 8px solid green;
      transform: rotate(135deg);
      opacity: 0;
    }
    .activity-indicator.show {
      opacity: 1;
      transition: opacity 0.3s linear 0s;
    }
`;

function addStyleSheetToHead (rules) {
  const styleSheet = document.createElement('style');
  styleSheet.id = STYLESHEET_ID;
  styleSheet.textContent = cssRules;
  document.head.appendChild(styleSheet);
}

function addDivToBody () {
  const div = document.createElement('div');
  div.className = ACTIVITY_CLASSNAME;
  document.body.appendChild(div);
  divActivity = div;
  return div;
}

// blink indicator to show receiving geo data
export function showIndicator () {
  divActivity.classList.add('show');
}
function hide (timeout) {
  setTimeout(() => {
    divActivity.classList.remove('show');
  }, timeout);
}

window.addEventListener('load', () => {
  // add style sheet if necessary
  if (!document.getElementById(STYLESHEET_ID)) {
    addStyleSheetToHead(cssRules);
  }
  // add div if necessary
  divActivity = document.querySelector(`.${ACTIVITY_CLASSNAME}`)
    || addDivToBody();
  // hide indicator on every transition end
  divActivity.addEventListener(
    'transitionend',
    () => hide(200));
});

