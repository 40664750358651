// wime-conf.js - my elevation configuration

import { haversineDistance } from './haversine.js';
import debug from './debug.js';

export const constants = {
  METERS_PER_FOOT: 0.3048,

  GRADE_DISTANCE_MIN: 10, // required distanceOffset to calculate grade (meters)
  GRADE_TIME_MIN: 10000,  // required timeOffset to calculate grade (ms)
  GRADE_HISTORY_MIN: 6,  // required number of history records

  VAM_DISTANCE_MIN: 10, // required distanceOffset to calculate vam (meters)
  VAM_TIME_MIN: 10000, // required timeOffset to calculate vam (ms)
  VAM_HISTORY_MIN: 6,  // required number of history records

  REQUIRED_POSITION_ACCURACY: 12, // meters
  REQUIRED_ALTITUDE_ACCURACY: 5, // meters
  MIN_ALTITUDE_TO_ACCURACY_RATIO: 0.5, // ignore small altitude changes
  MIN_COORDS_CHANGE_TO_ACCURACY_RATIO: 0.8, // ignore when small change

  MIN_VAM_TO_DISPLAY: 5, // prevents showing very small VAMs (m/hr)
  HISTORY_TIME_MAX: 180000,  // max amount of history to keep (ms)

  MIN_TIME_BETWEEN_SECTION_REFRESH: 5000  // min time between display refresh
  // (ms). increase this to reduce display "flashing"
};

export const wcGeo = navigator.geolocation;

export const WimeConf = {};
export function getHTMLElements () {
  WimeConf.pageLanding = document.getElementById('pageLanding');
  WimeConf.pageMain = document.getElementById('pageMain');
  WimeConf.divElevation = document.getElementById('divElevation');
  WimeConf.divVAM = document.getElementById('divVAM');
  WimeConf.divGrade = document.getElementById('divGrade');
  WimeConf.divReceiving = document.getElementById('divReceiving');
  WimeConf.divAboutModal = document.getElementById('modal-about');
}

export const elevationSourceName = {
  gps: 'GPS',
  openElevation: 'open-elevation.com'
};

export const geoLocationErrors = {
  1: `my elevation doesn't have permission to use your current location.`,
  2: `Geolocation service returned an internal error.`,
  3: `Geolocation service took too long to return information.`
};

// criteria that specifies how much history is requred for a
// given measurement
const HistReq = {
  'grade': {
    minDistance: constants.GRADE_DISTANCE_MIN,
    minTime: constants.GRADE_TIME_MIN,
    minHistory: constants.GRADE_HISTORY_MIN
  },
  'vam': {
    minDistance: constants.VAM_DISTANCE_MIN,
    minTime: constants.VAM_TIME_MIN,
    minHistory: constants.VAM_HISTORY_MIN
  }
};

class HistoryCriteria {
  constructor (measureType) {
    const crit = HistReq[measureType];
    this.minDistance = crit.minDistance;
    this.minTime = crit.minTime;
    this.minHistory = crit.minHistory;
  }
}
/**
 * the criteria that determines how much history is requred for a measurement
 * @param {string} measureType - 'grade' or 'vam'
 * @return {HistoryCriteria}
 */
export function historyCriteria (measureType) {
  return new HistoryCriteria(measureType);
}

// coords record must meet these criteria to be considered valid
export function isCoordsAccuracySufficient (coords) {
  return coords.accuracy <= constants.REQUIRED_POSITION_ACCURACY &&
    coords.altitudeAccuracy <= constants.REQUIRED_ALTITUDE_ACCURACY;
}

/**
 * true if c2's cylinder of accuracy does not contain c1's location.
 * In other words, true when location has moved outside the accuracy range.
 * @param {GeolocationCoordinates|WimeCoords|null} c1
 * @param {GeolocationCoordinates|WimeCoords} c2
 * @return {boolean}
 */
export function hasCoordsMovedBeyondAccuracy (
  c1,
  c2
) {
  if (!c1) { return true; }
  if (!c2.accuracy || !c2.altitudeAccuracy) { return false; }
  const dHorizontal = Math.abs(haversineDistance(c1, c2)),
    dVertical = Math.abs(c1.altitude - c2.altitude),
    {accuracy, altitudeAccuracy} = c2;
  const hRatio = dHorizontal / accuracy /
    constants.MIN_COORDS_CHANGE_TO_ACCURACY_RATIO;
  const vRatio = dVertical / altitudeAccuracy /
    constants.MIN_COORDS_CHANGE_TO_ACCURACY_RATIO;
  // debug.log(`hasCoordsMovedBeyondAccuracy:`, JSON.stringify({
  //   hRatio: Number(hRatio.toFixed(2)),
  //   vRatio: Number(vRatio.toFixed(2)),
  //   acc: Number(accuracy.toFixed(2)),
  //   altAcc: Number(altitudeAccuracy.toFixed(2)),
  // }));
  return (
    Math.abs(hRatio) >= 1 ||
    Math.abs(vRatio) >= 1
  );
}

