// wime-coords.js - type definitions for wime coordinates

export class WimeCoords extends Object {
  /**
   * @param {GeolocationCoordinates} [coords]
   */
  constructor (coords= undefined) {
    super();
    if (coords) {
      this.latitude = coords.latitude;
      this.longitude = coords.longitude;
      this.accuracy = coords.accuracy;
      this.altitude = coords.altitude;
      this.altitudeAccuracy = coords.altitudeAccuracy;
    } else {
      this.latitude = null;
      this.longitude = null;
      this.accuracy = null;
      this.altitude = null;
      this.altitudeAccuracy = null;
    }
    this.timeOffset = null; // offset from zeroTime
    this.distanceOffset = null;
    this.source = null;
  }

  /**
   * clone properties of existing WimeCoords
   * @param {WimeCoords|Object} wCoords
   */
  cloneFromWimeCoords (wCoords) {
    Object.assign(this, wCoords);
    return this;
  }

  /**
   * clone properties of an object
   * @param {Object} obj
   * @return {WimeCoords}
   */
  cloneFromObject (obj) {
    Object.assign(this, obj);
    return this;
  }
}
