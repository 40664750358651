// wime.js - client code for "what is my elevation"

import './wime-coords.js';
import {
  wcGeo,
  getHTMLElements,
  geoLocationErrors,
  constants, WimeConf
} from './wime-conf.js';
import './ugly-focus-ring.js';
import { showIndicator } from './activity-indicator.js';
import { updateElevationSection } from './elevation-section.js';
import { updateGradeSection } from './grade-section.js';
import { updateVAMSection } from './vam-section.js';
import {
  recordNewCoords,
  latestCoords
} from './coords-history.js';
import getWimeCoordsFromGeoPosition from './get-coords.js';
import debug from './debug.js';

// the last time the display was updated
let lastDisplayTime = 0;

/**
 * true if enough time has elapsed since last time display was refreshed
 * @return {boolean}
 */
function hasEnoughTimeSinceLastDisplay () {
  const now = Date.now();
  return now - lastDisplayTime >= constants.MIN_TIME_BETWEEN_SECTION_REFRESH;
}

function updateDisplayTime () {
  lastDisplayTime = Date.now();
}

/**
 * update all the display sections
 * @param {WimeCoords} [coords]
 */
function updateDisplay (coords = latestCoords()) {
  if (!hasEnoughTimeSinceLastDisplay()) {
    return;
  }
  updateElevationSection(coords);
  updateGradeSection(coords);
  updateVAMSection(coords);
  updateDisplayTime();
}

/**
 * process new Wime coords:
 * - store coords in history
 * - show a "blink" indicator on screen if coords stored
 * - update screen
 * @param {WimeCoords} wCoords
 */
function handleNewWimeCoords (wCoords) {
  const isRecorded = recordNewCoords(wCoords);
  if (isRecorded) {showIndicator();}
  updateDisplay(wCoords);
}

/**
 * handle a newly received geolocation position
 * @param {GeolocationPosition} position
 */
function handleNewPosition (position) {
  // debug.log('handleNewPosition()');

  getWimeCoordsFromGeoPosition(position)
    .then(wCoords => {
      handleNewWimeCoords(wCoords);
    })
    .catch(err => {
      console.error(`handleNewPosition err:`, err);
    });
}

// start a watcher for new position data
function startPositionWatcher () {
  wcGeo.watchPosition(position => {
    handleNewPosition(position);
  }, err => {
    console.error(`startGeotracking err:`, err);
  }, {
    enableHighAccuracy: true
  });
}

// initialize the app
function initMyElevation () {
  // debug.log(`initMyelevation()`);

  // store references to all pertinent HTML elements
  getHTMLElements();

  // listen for button clicks on landing "page"
  const pageLanding = WimeConf.pageLanding;
  pageLanding.addEventListener('click', (ev) => {
    const target = ev.target;
    if (target.tagName !== 'BUTTON') {
      return;
    }
    const targetID = target.id;
    if (targetID === 'btnShowInfo') {
      const bsModal = new bootstrap.Modal(WimeConf.divAboutModal);
      bsModal.show();
    } else if (targetID === 'btnShowElevation') {
      pageLanding.classList.add('d-none');
      displayElevation();
    }
  });
}

// start displaying elevation
function displayElevation () {
  // show the elevation page
  WimeConf.pageMain.classList.remove('d-none');

  // get the current position
  new Promise((resolve, reject) => {
    wcGeo.getCurrentPosition(pos => {
      resolve(pos);
    }, err => {
      reject(err);
    });
  })

    .then(position => {
      // log coords to server
      debug.logCoords(position.coords);
      return position;
    })

    .then(position => {
      // handle the position
      handleNewPosition(position);
    })

    .then(() => {
      // start watching for position changes
      startPositionWatcher();
    })

    .catch(err => {
      console.error(`displayElevation err:`, err);
      const message = (1 <= err.code && err.code <= 3) ?
        geoLocationErrors[err.code] : 'unknown reason';
      // TODO TERRY make this alert a modal
      alert(`Unable to start elevation tracking:\n\n ${message}`);
    });

}

window.addEventListener('load', () => {
  initMyElevation();
});
