/*
Haversine formula for distance between two map coordinates
ref: http://stackoverflow.com/questions/1502590/calculate-distance-between-two-points-in-google-maps-v3
*/
function radians (x) {
  return x * Math.PI / 180;
}

/**
 * return distance between two Coordinates in meters
 * coordinates ref: https://developer.mozilla.org/en-US/docs/Web/API/Coordinates
 * @param {object} c1
 * @param {object} c2
 * @return {number|null}
 */
export function haversineDistance (c1, c2) {
  try {
    if (c1.latitude === null || c2.latitude === null ||
      c1.longitude === null || c2.longitude === null) {
      return null;
    }
    const R = 6378137; // Earth’s mean radius in meter
    const dLat = radians(c2.latitude - c1.latitude);
    const dLong = radians(c2.longitude - c1.longitude);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(radians(c1.latitude)) * Math.cos(radians(c2.latitude)) *
      Math.sin(dLong / 2) * Math.sin(dLong / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  } catch (err) {
    throw new Error(err.message);
  }
}
