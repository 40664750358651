// update-section.js - update the specific section on the screen

import debug from './debug.js';

 /**
 * @param {HTMLElement} divSection
 * @param {string} title - HTML string
 * @param {number|null} value
 * @param {string} units - HTML string
 * @param {number} direction - 0: none, <0: down, >0: up
 * @param {string} footnote - HTML sring
 */
 export function updateSection (divSection, {
  title,
  value,
  units,
  direction,
  footnote
}) {
  const els = {
    divTitle: divSection.querySelector('.title'),
    spanValue: divSection.querySelector('.value span'),
    divUnits: divSection.querySelector('.units'),
    divDirection: divSection.querySelector('.value .direction'),
    divFootnote: divSection.querySelector('.footnote')
  };
  // debug.log('updateSection els:', els);
  if (els.divTitle) {
    els.divTitle.innerHTML = title;
  }
  if (els.spanValue) {
    els.spanValue.innerHTML =
      (!isNaN(value) && value != null) ? String(Math.abs(value)) : '__';
  }
  if (els.divUnits) {
    els.divUnits.innerHTML = units;
  }
  if (els.divDirection) {
    let dirContent = '';
    if (direction > 0) {
      dirContent = '&uarr;';
    } else if (direction < 0) {
      dirContent = '&darr;';
    }
    els.divDirection.innerHTML = dirContent;
  }
  if (els.divFootnote) {
    els.divFootnote.innerHTML = footnote;
  }
}
